<template>
  <div class="guest-pay">
    <div class="nav-bar row w-100 d-flex flex-row-reverse align-items-center pr-3">
      <a :href="`${ invoice?.data ? invoice.data.loginUrl : '/' }`" class="nav-link" aria-label="Sign In Link">
        <b-img src="/images/payments/log-in.svg" class="mr-1" />
        Sign In
      </a>
    </div>
    <div v-if="!paymentConfirmed">
      <div class="row w-100 m-0">
        <div class="col-lg-7 col-md-6 d-flex justify-content-center">
          <div class="payment-info-width">
            <h4 class="font-weight-bold mt-5">Welcome to</h4>
            <h2 class="font-weight-bold mt-1">{{ invoice.data.contact.name }}<br>Quick Pay</h2>
            <div class="mt-4">Enter your payment information to be used for invoice number <b>{{ invoice.data.invoiceNumber }}</b></div>
            <b-form class="fields form-group">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <div class="row mb-0 pb-0">
                  <h6 class="font-weight-bold pl-3 mt-2 mb-3">
                    Payment Information
                  </h6>
                  <credit-card-form
                    @changed="cardInfoChanged"
                  />
                </div>
                <div class="row">
                  <h6 class="font-weight-bold pl-3 mt-0 mb-3">
                    Address Information
                  </h6>
                  <address-form
                    :allow-existing-addresses="false"
                    :show-kind="false"
                    @changed="addressInfoChanged"
                  />
                </div>

                <div class="row">
                  <b-col cols="12" md="12">
                    <h6 class="font-weight-bold mb-3 mt-1">
                      An email receipt will go to the account's email.
                      For a copy sent to another email, please specify below.
                    </h6>
                    <b-form-group
                      id="email-address-group"
                      label="Additional Email Receipts"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="email"
                        rules="email"
                      >
                        <b-form-input
                          id="email-input-text"
                          v-model="emailInputText"
                          type="text"
                          :state="!errors[0] ? null: true"
                        />
                        <b-form-invalid-feedback :state="!errors[0]">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </div>

                <b-form-invalid-feedback id="input-live-feedback">
                  {{ errors }}
                </b-form-invalid-feedback>
              </validation-observer>
            </b-form>
          </div>
        </div>
        <div class="col-lg-5 col-md-6 pt-5 justify-content-center bg-payment-summary">
          <b-card id="cart" class="p-1 mb-4 mx-auto payment-summary">
            <h4 class="font-weight-bold mt-1 mb-4">
              Payment Summary
            </h4>

            <b-card-text>
              <em v-if="isPaidInvoice && !invoiceError">
                This invoice has already been paid. Login below to view more details about invoice #{{ invoice.data.invoiceNumber }}.
              </em>

              <em v-if="invoiceError">
                There was an issue locating that invoice. Please login below or call us for more details regarding this invoice.
              </em>

              <div v-if="invoice && !invoiceError && !isPaidInvoice" style="font-size: 0.9rem">
                <p>Invoice Number <b>{{ invoice.data.invoiceNumber }}</b></p>
                <p v-if="invoice.data.description">
                  Invoice Description: {{ invoice.data.description }}
                </p>
              </div>
            </b-card-text>

            <div v-if="invoice && !isPaidInvoice && !invoiceError" class="d-flex flex-row justify-content-between">
              <div>
                <strong>Total</strong>
              </div>
              <div>
                <strong>{{ invoice.data.total | currency }}</strong>
              </div>
            </div>
            <hr>
            <b-form-checkbox
              v-model="tosAccepted"
            >
              <span class="small">
                By clicking 'Submit', I accept the <a class="link-primary" :href="newTosUrl" target="_blank">Terms and Conditions</a>
              </span>
            </b-form-checkbox>
            <b-form-group class="guest-submit-btn d-flex justify-content-center">
              <ct-centered-spinner v-if="isBusy" class="pb-4" />
              <b-button
                v-else-if="!invoiceError && !isPaidInvoice"
                class="btn-preferred"
                :disabled="isBusy || !tosAccepted"
                @click="savePaymentInfo"
              >
                Submit Payment
              </b-button>
            </b-form-group>
          </b-card>

          <div v-if="paymentMethodErrors" class="p-1 mb-4 mx-auto payment-failed">
            <banner
              :message="paymentMethodErrors"
              :variant="'failed'"
            />
          </div>
          <hr>
          <b-table class="mx-auto w-75" :items="invoice.data.lineItems" :fields="fields">
            <template #cell(description)="data">
              <div>{{ data.item.description }}</div>
              <div v-if="data.item.showItems">
                <div v-for="(item, index) in data.item.invoiceLineItems" :key="index" class="pl-2">
                  <small>&#8627;{{ item.description }}</small>
                </div>
              </div>
            </template>
            <template #cell(price)="data">{{ data.item.price | currency }}</template>
          </b-table>
        </div>
      </div>
    </div>
    <div v-else>
      <h1 class="text-center">
        Payment Confirmed
      </h1>
      <p class="text-center" style="color: #777">
        An email will be sent to the account {{ emailInputText ? 'and ' + emailInputText : '' }} notifying your payment. <br>
        Thank you for your business!
      </p>

      <hr>

      <p class="text-center">
        You can
        <a :href="`${ invoice.data.loginUrl }`">
          login
        </a>
        or return to
        <a :href="`https://${ invoice.data.contact.url }`"> {{ invoice.data.contact.url }}</a>
      </p>
    </div>
    <guest-pay-confirmation-modal v-if="invoice" :invoice="invoice.data" :confirmation-number="confirmationNumber" />
  </div>
</template>

<script>
// TODO:
// Add spec tests to tokenizer to ensure that guest card info is able to be used to pay the clients invoice / shows as deleted / etc.
// Pulled out invoice verified POC. Move concept to actual verification page when it exists
// Remove hard coded login url link in future once roll out to more websites

import TokenizerClient from '@/common/modules/tokenizer-client'
import AddressForm from '@/components/shared/forms/AddressForm'
import CreditCardForm from '@/components/shared/forms/CreditCardForm'
import GuestPayConfirmationModal from '@/components/GuestPayConfirmationModal'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import Banner from '@/components/shared/Banner.vue'

export default {
  name: 'GuestPay',
  components: {
    Banner,
    GuestPayConfirmationModal,
    AddressForm,
    CreditCardForm,
    CtCenteredSpinner,
    ValidationObserver,
  },
  data() {
    return {
      invoice: null,
      isPaidInvoice: null,
      invoiceError: false,
      isBusy: false,
      invoiceNumber: '',
      confirmationNumber: '',
      emailInputText: '',
      addressInfo: {},
      paymentMethodInfo: {},
      paymentMethodErrors: null,
      errors: [],
      invoiceInputText: '',
      invalidInvoiceError: null,
      verifiedInvoice: false,
      paymentConfirmed: false,
      tosAccepted: false,
      fields: [
        { key: 'description' },
        { key: 'price', sortable: true },
        { key: 'quantity', sortable: true },
      ],
    }
  },
  async mounted() {
    this.setIsGuestPay(true)
    this.isBusy = true
    this.invoice = await this.$store.dispatch('invoices/fetchInvoice', {
      params: {
        id: this.$route.params.id,
      },
    })
    this.setupFacades()

    this.isBusy = false

    if (this.invoice.data.error) {
      this.invoiceError = true
    }

    this.isPaidInvoice = this.invoice.data.status !== 'unpaid'
  },
  computed:{
    ...mapGetters('website', ['newTosUrl']),
  },
  beforeDestroy () {
    this.setIsGuestPay(false)
  },
  methods: {
    ...mapActions('paymentMethods', ['setIsGuestPay']),
    setupFacades(){
      const facades = []
      for (const item of _.cloneDeep(this.invoice.data.lineItems)) {
        if (item.invoiceFacade) {
          const current_facade = facades.find(x => x.id === item.invoiceFacade.id)
          if (!current_facade) {
            const invoiceFacade = item.invoiceFacade
            invoiceFacade.price = item.price
            invoiceFacade.quantity = 1
            invoiceFacade['invoiceLineItems'] = item.invoiceFacade.showItems ? [item] : []
            facades.push(invoiceFacade)
          } else {
            current_facade.price += item.price
            if (item.invoiceFacade.showItems) {
              current_facade['invoiceLineItems'].push(item)
            }
          }

          let index = this.invoice.data.lineItems.findIndex(x => x.id === item.id)
          this.invoice.data.lineItems.splice(index, 1)
        }
      }
      this.invoice.data.lineItems = this.invoice.data.lineItems.concat(facades)
    },
    verifyInvoiceNumber() {
      this.verifiedInvoice = this.invoiceInputText === this.invoice.data.invoiceNumber
      this.verifiedInvoice ? this.invalidInvoiceError = true : this.invalidInvoiceError = false
    },
    addressInfoChanged(info) {
      this.addressInfo = info
    },
    cardInfoChanged(info) {
      this.paymentMethodInfo = info
    },
    async savePaymentInfo() {
      const valid = await this.$refs.observer.validate()
      if (!valid) return

      this.isBusy = true

      const tokenizerClient = new TokenizerClient(this.$store, true, { invoice_id: this.invoice.data.id })

      this.paymentMethodInfo.billing_address = tokenizerClient.addressToTokenizer(this.addressInfo)
      this.paymentMethodInfo.guest_card = true

      const result = await tokenizerClient.createPaymentMethod(this.paymentMethodInfo, 'card')
      await this.verifyCard(result)
    },
    async verifyCard(result) {
      result.success ? this.successfulResult(result) : this.unSuccessfulResult(result)
    },
    successfulResult(result) {
      const addedPayableId = result.data.id
      this.payInvoice(addedPayableId)
    },
    unSuccessfulResult(result) {
      if (_.isObjectLike(result.data) && result.data.errors) {
        this.errors = Object.keys(result.data.errors).map(key =>
          `${_.startCase(key)}: ${_.startCase(result.data.errors[key])}`
        )
        this.paymentMethodErrors = this.errors.join(', ')
      } else if(result.data.message === 'Bad card Issuer') {
        this.paymentMethodErrors = 'This card is not allowed by our processor. Please use a different card.'
      } else {
        this.paymentMethodErrors = 'There was problem verifying your card.'
      }
    },
    async payInvoice(payableId) {
      const ids = this.invoice.data.id
      const accountId = this.invoice.data.ownerId
      const email = this.emailInputText

      const response = await this.$store.dispatch('invoices/guestPayInvoices', {
        accountId,
        payableId,
        ids,
        email,
      })

      if (response.data.success) {
        this.confirmationNumber = response.data.confirmationNumber
        this.$bvModal.show('guest-pay-confirmation-modal')
        this.paymentConfirmed = true

        // TODO: update once new structure put in place for payment info
        // await this.$store.dispatch('invoices/notifyGuestPayment', {
        //   ids,
        //   accountId,
        // })
      } else {
        this.paymentMethodErrors = response.data.error.message
      }
      this.isBusy = false
    },
  },
}
</script>

<style lang="scss" scoped>

h6 {
  display: block;
  white-space: pre-line;
  line-height: 1.5rem;
}

.verify-invoice {
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify-input {
  min-height: 46px !important;
}

.verify-button {
  margin-left: 15px;
}

.guest-submit-btn {
  margin: 1.25em 0 0;
}

.guest-pay {
  position: fixed;
  left: 0 !important;
  right: 0 !important;
  width: 100%;
  height: 100%;
  margin-top: -1.25rem !important;
  overflow-y: scroll;
}

.nav-bar {
  background-color: #000864;
  height: 3.5rem;
  margin: 0 !important;
}

.nav-link {
  color: #FFF;
  font-size: 0.9rem;
}

.payment-summary {
  width: 90%;
  max-width: 500px;
  min-width: 300px;
}

.payment-failed {
  width: 90%;
  max-width: 500px;
  min-width: 300px;
}

.bg-payment-summary {
  background-color: #F5F5F5;
}

::v-deep .form-control {
  min-height: 41px !important;
  height: 41px !important;
  border-radius: 6px;
  border: 1px solid #C0C0C0;
}

::v-deep .custom-select {
  min-height: 41px !important;
  height: 41px !important;
  border-radius: 6px;
  border: 1px solid #C0C0C0;
}

.payment-info-width {
  width: 90%;
  max-width: 700px;
}

@media only screen and (max-width: 991px) {
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  h6 {
    font-size: .9rem;
  }
}
</style>
